import { ApiResponse } from '../models/ApiResponse';
import {
  CreateDataExportJobRequest,
  DataExportOptions,
  DataExportOptionsFilter,
  DataJobExportFilter,
  DataJobOverviewResponse,
  GenerateExampleFileRequest,
} from '../models/DataExport';
import BaseService from './BaseService';

class DataExportService extends BaseService {
  public static async downloadExampleFile(request: GenerateExampleFileRequest): Promise<Blob> {
    const res = await this.postRaw('/v1/exports/example', request, { responseType: 'arraybuffer' });

    if (res.status !== 200) {
      throw res.data;
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];

    return new Blob([fileContent], { type: fileType });
  }

  public static async downloadEvidenceReport(clientFormId: string): Promise<Blob> {
    const res = await this.getRaw(`/v1/exports/${clientFormId}/evidence-report`, { responseType: 'arraybuffer' });

    if (res.status !== 200) {
      throw res.data;
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];
    return new Blob([fileContent], { type: fileType });
  }

  public static async downloadDistributionLogs(distributionId: string): Promise<Blob> {
    const res = await this.getRaw(`/v1/exports/${distributionId}/distribution-logs`, { responseType: 'arraybuffer' });

    if (res.status !== 200) {
      throw res.data;
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];
    return new Blob([fileContent], { type: fileType });
  }

  public static createExport(request: CreateDataExportJobRequest): Promise<ApiResponse<DataJobOverviewResponse>> {
    return this.post(`/v1/exports/create`, request);
  }

  public static getExportOptions(request: DataExportOptionsFilter): Promise<ApiResponse<DataExportOptions>> {
    return this.post(`/v1/exports/options`, request);
  }

  public static getExportJobs(filter: DataJobExportFilter = {}): Promise<ApiResponse<DataJobOverviewResponse[]>> {
    return this.post(`/v1/exports/paged`, filter);
  }

  public static cancelExport(dataJobId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/exports/${dataJobId}/cancel`, {});
  }

  public static deleteExport(dataJobId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/exports/${dataJobId}`);
  }

  public static undoDeleteExport(dataJobId: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/exports/${dataJobId}/undo-delete`);
  }

  public static downloadUncontrolledCopy(clientFormId: string): Promise<Blob> {
    return this.getRaw(`/v1/exports/${clientFormId}/uncontrolled-copy`, { responseType: 'arraybuffer' }).then((res) => {
      if (res.status !== 200) {
        throw res.data;
      }

      const fileContent = res.data;
      const fileType = res.headers['content-type'];
      return new Blob([fileContent], { type: fileType });
    });
  }
}

export default DataExportService;
